import * as THREE from "three";
import {TweenLite, Back} from "gsap";
import earth from '~/images/planet/2_no_clouds_4k.jpg';
import bump from '~/images/planet/elev_bump_4k.jpg';
import water from '~/images/planet/water_4k.png';
import clouds from '~/images/planet/fair_clouds_8k.jpg';
import marker from '~/images/planet/location-pin.png';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";

const textureLoader = new THREE.TextureLoader();
const axesHelper = new THREE.AxesHelper( 15 );


let width = window.innerWidth,
    height = window.innerHeight;

let scene = new THREE.Scene({
    background: null
});

let camera = new THREE.PerspectiveCamera(45, width / height, 0.1, 1000);
camera.position.z = 2;

let renderer = new THREE.WebGLRenderer({
    alpha: true,
    antialias: true
});
renderer.setSize(width, height);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

scene.add(new THREE.AmbientLight(0x222222));

let light = new THREE.DirectionalLight(0xffffff, 1);
light.position.set(1, 0, 1);
scene.add(light);

const imageNoCloud = textureLoader.load(earth);
const imageBump = textureLoader.load(bump);
const imageWater = textureLoader.load(water);
const imageClouds = textureLoader.load(clouds);
const imageMarker = textureLoader.load(marker);

const basicTexture = new THREE.MeshBasicMaterial({
    map: imageNoCloud,
});

let complexTexture = new THREE.MeshPhongMaterial({
    map: imageNoCloud,
    bumpMap: imageBump,
    bumpScale: 0.01,
    specularMap: imageWater,
    specular: new THREE.Color(0.12, 0.12, 0.12)
});


let globe = new THREE.Mesh(
    new THREE.SphereGeometry(10, 50, 50),
    complexTexture
);
let cloudsSphere = new THREE.Mesh(
    new THREE.SphereGeometry(10.05, 50, 50),
    new THREE.MeshPhongMaterial({
        alphaMap: imageClouds,
        transparent: true
    })
)

//globe.add(axesHelper);
scene.add(globe);
scene.add(cloudsSphere);

let orbitControls = new OrbitControls(camera, renderer.domElement);

orbitControls.rotateSpeed = 0.5;
orbitControls.maxAzimuthAngle = 1;
orbitControls.autoRotate = true;
orbitControls.autoRotateSpeed = 0.1;
orbitControls.enableDamping = true;
orbitControls.enableZoom = false;
orbitControls.maxPolarAngle = Math.PI - 0.7;
orbitControls.minPolarAngle = 0.7;

render();
document.body.appendChild(renderer.domElement);

camera.position.set(25, 20, 0);
camera.lookAt(globe.position);


let rotSpeed = 0.001;

function checkRotation() {

    let x = camera.position.x,
        y = camera.position.y,
        z = camera.position.z;

    camera.position.x = x * Math.cos(rotSpeed) + z * Math.sin(rotSpeed);
    camera.position.z = z * Math.cos(rotSpeed) - x * Math.sin(rotSpeed);

    camera.lookAt(scene.position);

}

function render() {
    requestAnimationFrame(render);
    orbitControls.update();
    //checkRotation();
    cloudsSphere.rotation.y = cloudsSphere.rotation.y + 0.0001;
    renderer.render(scene, camera);
}



// Marker Proto
let markerProto = {
    latLongToVector3: function latLongToVector3(latitude, longitude, radius, height) {
        var phi = latitude * Math.PI / 180;
        var theta = (longitude - 180) * Math.PI / 180;

        var x = -(radius + height) * Math.cos(phi) * Math.cos(theta);
        var y = (radius + height) * Math.sin(phi);
        var z = (radius + height) * Math.cos(phi) * Math.sin(theta);

        return new THREE.Vector3(x, y, z);
    },
    marker: function marker(size, color, vector3Position) {
        let markerGeometry = new THREE.SphereGeometry(size, 32, 32);
        let markerMaterial = new THREE.MeshBasicMaterial({
            color: color
        });

        markerMaterial = new THREE.SpriteMaterial({
            map: imageMarker
        });

        let markerMesh = new THREE.Mesh(markerGeometry, markerMaterial);
        let markerSprite = new THREE.Sprite(markerMaterial);
        markerSprite.scale.set(0.444, 1.024, 1);
        //vector3Position.x += 0.2;
        markerSprite.position.copy(vector3Position);


        return markerSprite;
    }
};

let group = new THREE.Group();
scene.add( group );

// Place Marker
function placeMarker(object, options) {
    let position = markerProto.latLongToVector3(options.latitude, options.longitude, options.radius, options.height);
    let marker = markerProto.marker(options.size, options.color, position);
    group.add(marker);
}



placeMarker(globe, {
    latitude: 47.218371,
    longitude: -1.553621,
    radius: 0.5,
    height: 10 - 0.4,
    size: 0,
    color: 0xff0000
});

placeMarker(globe, {
    latitude: 30.218371,
    longitude: 180.553621,
    radius: 0.5,
    height: 10.2,
    size: 0.2,
    color: 0xff0000
});

let selectedObject = null;
const raycaster = new THREE.Raycaster();
const pointer = new THREE.Vector2();

function onPointerMove( event ) {

    if ( selectedObject ) {
        // console.log(selectedObject);
        // selectedObject.scale.set(0.444, 1.024, 1);
        //selectedObject.material.color.set( '#69f' );
        // selectedObject = null;

    }

    pointer.x = ( event.clientX / window.innerWidth ) * 2 - 1;
    pointer.y = - ( event.clientY / window.innerHeight ) * 2 + 1;

    raycaster.setFromCamera( pointer, camera );

    const intersects = raycaster.intersectObject( group, true );

    if ( intersects.length > 0 ) {

        const res = intersects.filter( function ( res ) {

            return res && res.object;

        } )[ 0 ];

        if ( res && res.object && selectedObject === null) {

            selectedObject = res.object;
            //selectedObject.material.color.set( '#fff' );
            //selectedObject.scale.set(2, 2, 0);
            document.body.classList.add('hover');
            TweenLite.fromTo(
                selectedObject.scale,
                0.3,
                {x:0.444, y:1.024},
                {x:0.888, y:2.048, ease: Back.easeOut});

        }

    } else if ( selectedObject ) {
        document.body.classList.remove('hover');
        TweenLite.to(selectedObject.scale,0.3, {x:0.444, y:1.024});

        //selectedObject.scale.set(0.444, 1.024, 0);
        //selectedObject.material.color.set( '#69f' );
        selectedObject = null;

    }

}

document.addEventListener( 'pointermove', onPointerMove );
